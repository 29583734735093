<template>
  <div class="flex flex-row w-full" v-if="workflow">
    <ConfirmDialog group="sandbox-warning" id="warning-dialog"></ConfirmDialog>
    <Toast
      group="success-toast"
      position="top-right"
      :pt="{
        container: { class: 'rounded-lg' },
        root: { class: 'opacity-100' },
      }"
    >
      <template #container="{ message, closeCallback }">
        <section
          class="flex flex-row w-full bg-white border border-spreedly-blue-600 rounded-md"
        >
          <div class="bg-success-green w-[15px] rounded-l"></div>
          <div
            class="m-3 flex flex-row border-y-0 border-l-0 border-r-1 border border-spreedly-gray-500 pr-3 items-center"
          >
            <i
              class="pi pi-check-circle text-success-green text-3xl self-center"
            ></i>

            <div
              class="flex flex-col mx-4 font-normal text-spreedly-gray-600 w-[200px]"
            >
              <div>
                {{ message.summary }}
              </div>
            </div>

            <button
              type="button"
              @click="restoreDraftWorkflow()"
              id="undo-workflow-reset"
              class="whitespace-nowrap border border-spreedly-blue-600 my-2 px-2 rounded-md text-spreedly-blue-600 bg-white hover:cursor-pointer hover:text-spreedly-blue-700 hover:border-spreedly-blue-700"
            >
              {{ $t("undo") }}
            </button>
          </div>
          <div class="flex flex-col justify-center w-7 -ml-2 items-center">
            <button
              type="button"
              aria-label="close workflow toast notice"
              id="close-workflow-toast"
              @click="closeCallback"
              class="text-spreedly-gray-600 hover:bg-spreedly-blue-300 rounded-4xl w-6 h-6 align-middle"
            >
              <i class="pi pi-times mt-1"></i>
            </button>
          </div>
        </section>
      </template>
    </Toast>

    <SpreedlyContainer
      v-if="buildingWorkflow"
      class="h-full !mb-0 !pb-8 w-full"
    >
      <WorkflowVerticalStepper :position="-1">
        <Skeleton width="200px" height="32px" class="mb-2"></Skeleton>
        <Skeleton width="313px" height="40px"></Skeleton>
      </WorkflowVerticalStepper>
      <WorkflowVerticalStepper :position="0">
        <Skeleton width="300px" height="32px" class="mb-2"></Skeleton>
        <Skeleton width="710px" height="102px"></Skeleton>
      </WorkflowVerticalStepper>
    </SpreedlyContainer>
    <SpreedlyContainer v-else class="!mb-6 !pb-0 w-full">
      <Message
        v-if="successMessageVisible"
        class="-mx-8 -mt-8 w-[calc(100%+4rem)] justify-start"
        severity="success"
        :closable="false"
      >
        <i18n-t
          scope="global"
          :keypath="'successMessage.workflowCreated'"
          tag="p"
        >
          <template v-slot:workflow>
            <span class="font-bold">{{ workflow.name }}</span>
          </template>
        </i18n-t>
      </Message>
      <Message
        v-if="errors.length"
        class="-mx-8 w-[calc(100%+4rem)] justify-start"
        :class="successMessageVisible ? '-mt-6' : '-mt-8'"
        severity="error"
        :closable="false"
        ><ul v-if="errors.length > 1" role="list" class="list-disc pl-6">
          <li v-for="error in errors">{{ error }}</li>
        </ul>
        <span v-else>{{ errors[0] }}</span>
      </Message>
      <div
        class="flex flex-col justify-between"
        :class="{
          'h-[calc(100%-56px)]': successMessageVisible || errors.length,
          'h-[calc(100%-112px)]': successMessageVisible && errors.length,
          'h-full': !successMessageVisible && !errors.length,
        }"
      >
        <div
          v-if="
            !togglePaymentCapabilities &&
            store.hasComposerEnabledGatewayConnections()
          "
          class="flex flex-row justify-end items-center mb-2"
        >
          <SpreedlyButton
            id="view-payment-capabilities"
            :text="$t('viewCapabilities')"
            inverse
            @click="togglePaymentCapabilities = true"
          ></SpreedlyButton>
        </div>
        <div class="flex flex-row relative">
          <form
            @keyup.enter="checkForInvalidForm(v.$invalid)"
            class="relative"
            @submit.prevent="checkForInvalidForm(v.$invalid)"
            :class="width >= 800 ? 'w-[calc(100%-209.56px)]' : 'w-[95%]'"
          >
            <WorkflowVerticalStepper :position="-1">
              <div class="flex flex-col" v-if="action === 'update'">
                <div class="flex flex-row pb-2">
                  <span class="font-bold">{{
                    `"${workflow.name}" ${$t("key")}`
                  }}</span>
                  <div class="self-center ml-4">
                    <div
                      v-if="isSandbox"
                      class="border border-success-green bg-success-green-light text-spreedly-gray-600 rounded-xl px-2 mr-2 text-xs"
                    >
                      {{ $t("sandbox") }}
                    </div>
                  </div>
                </div>
                <SpreedlyCopyToClipboard
                  class="bg-spreedly-blue-100 rounded-lg !mb-2 lg:mb-4"
                  :token="workflow.key as string"
                ></SpreedlyCopyToClipboard>
              </div>
              <div v-else-if="action === 'create'">
                <div class="field">
                  <label
                    class="text-sm"
                    :class="{
                      'p-error': v.name.$invalid && submitted,
                    }"
                    for="workflow-name-input"
                    >{{ $t("workflows.name") }}</label
                  >
                  <div class="mt-2 pb-4 w-[300px]">
                    <InputText
                      class="mx-2 w-full"
                      maxlength="50"
                      v-model.trim="v.name.$model"
                      id="workflow-name-input"
                      type="text"
                    />
                    <div v-if="v.name.$invalid && submitted" class="mt-0">
                      <small class="p-error">{{
                        v.name.required.$message
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="self-center">
                  <Checkbox
                    :disabled="!hasPermission"
                    v-model="v.sandbox.$model"
                    input-id="workflow-sandbox-checkbox"
                    variant="outlined"
                    @update:model-value="confirmSandboxToggle(v.sandbox.$model)"
                    binary
                  />
                </div>

                <label for="workflow-sandbox-checkbox" class="self-center ml-2">
                  {{ $t("workflows.sandboxMode") }}
                </label>
                <mdicon
                  name="information-outline"
                  class="text-spreedly-blue-700 px-2 hover:cursor-pointer"
                  data-testid="info-icon"
                  v-tooltip="$t('workflows.sandboxModeInfo')"
                ></mdicon>
              </div>
            </WorkflowVerticalStepper>
            <WorkflowStep
              v-for="step in steps"
              :step="step"
              :key="step.uuid"
            ></WorkflowStep>
          </form>
        </div>
        <div class="flex flex-row justify-between my-8">
          <div class="flex flex-shrink-0 flex-wrap items-center justify-start">
            <SpreedlyButton
              class="mr-4"
              :text="$t('reset')"
              :id="`cancel-${action}-workflow-button`"
              :inverse="true"
              :disabled="disableForm()"
              @click="reset()"
            ></SpreedlyButton>
            <SpreedlyButton
              class="!mr-0"
              :disabled="disableForm()"
              :id="`${action}-workflow-button`"
              :icon="{ position: 'left', state: formState }"
              :text="$t(formState)"
              @click="checkForInvalidForm(v.$invalid)"
            ></SpreedlyButton>
            <span
              class="text-sm font-bold text-spreedly-red ml-4"
              v-if="
                errors.includes($t('workflows.validationError')) ||
                errors.includes($t('errorMessage.generic'))
              "
            >
              {{ $t("workflows.unableToSave") }}
            </span>
          </div>
          <div class="flex flex-shrink-0 justify-end items-center">
            <button
              v-if="
                store.hasPermission('workflow.delete') && action === 'update'
              "
              :disabled="
                workflow.key === currentEnvironment.default_workflow_key
              "
              v-tooltip.bottom="
                workflow.key === currentEnvironment.default_workflow_key
                  ? $t('workflows.deleteTooltip')
                  : null
              "
              type="button"
              @click="confirmDeleteWorkflow"
              id="delete-workflow-button"
              class="px-4 py-2 max-h-[42px] inline-flex disabled:cursor-not-allowed justify-center items-center rounded whitespace-nowrap text-spreedly-blue-600 disabled:text-spreedly-gray-400 hover:text-spreedly-blue-700 cursor-pointer"
            >
              {{ $t("workflows.delete") }}
            </button>
          </div>
        </div>
      </div>
    </SpreedlyContainer>
    <PaymentCapabilitiesDrawer
      :composer="true"
      v-model:toggle-payment-capabilities="togglePaymentCapabilities"
    ></PaymentCapabilitiesDrawer>
    <ConfirmDialog
      group="delete-workflow-warning"
      id="delete-dialog"
    ></ConfirmDialog>
    <ConfirmDialog
      group="save-workflow-warning"
      id="warning-dialog"
    ></ConfirmDialog>
  </div>
</template>
<script lang="ts" setup>
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import WorkflowVerticalStepper from "@/components/WorkflowVerticalStepper.vue";
import PaymentCapabilitiesDrawer from "@/components/PaymentCapabilitiesDrawer.vue";
import WorkflowStep from "@/components/WorkflowStep.vue";
import Skeleton from "primevue/skeleton";
import Message from "primevue/message";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import {
  computed,
  onBeforeMount,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import i18n from "@/i18n";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import router from "@/router";
import { useVuelidate } from "@vuelidate/core";
import {
  listRecoverConfigurations,
  listFailureReasons,
} from "@/services/RecoverConfigurationsService";
import {
  createWorkflow,
  deleteWorkflow,
  updateWorkflow,
} from "@/services/WorkflowService";
import type {
  Workflow,
  WorkflowStep as WorkflowStepType,
} from "@/services/WorkflowService";

import { onBeforeRouteLeave } from "vue-router";
import {
  setGateways,
  resetComposable,
  useWorkflow,
  setSteps,
} from "@/composables/useWorkflow";
import { deepCopy } from "@/services/HelperService";
import { useBreakpoints } from "@/composables/useBreakpoints";
import { required } from "@vuelidate/validators";
import { updateEnvironment } from "@/services/EnvironmentService";
import { useConfirm } from "primevue/useconfirm";

import { useToast } from "primevue/usetoast";
import useEventBus from "@/composables/useEventBus";

const errors = ref<string[]>([]);
const store = useSettingsStore();
const { currentEnvironment, currentOrganization } = storeToRefs(store);
const {
  workflow,
  buildingWorkflow,
  action,
  formState,
  submitted,
  isSandbox,
  steps,
  recoverConfigurations,
  failureReasons,
} = useWorkflow();
const togglePaymentCapabilities = ref(false);
const { width } = useBreakpoints();
const confirm = useConfirm();
const recoverAllowed = computed(
  () => isSandbox.value || store.currentOrganization.allow_recover
);
const { bus } = useEventBus();
const toast = useToast();
const emit = defineEmits(["refresh"]);
const draftWorkflow = ref<Workflow>();
const successMessageVisible = ref(false);

onBeforeMount(async () => {
  buildingWorkflow.value = true;
  await setupGateways();
  setSteps();
  await setRecoverConfigurations();
  checkForErrors();
  buildingWorkflow.value = false;
  if (
    router.options.history.state.successMessage &&
    action.value === "update"
  ) {
    successMessageVisible.value = true;
    setTimeout(() => {
      successMessageVisible.value = false;
      router.options.history.replace(router.options.history.location, {
        successMessage: false,
      });
      router.options.history.state.successMessage = false;
    }, 5000);
  }
});

function checkForErrors() {
  if (
    checkForRecoverConfigurations() &&
    !errors.value.includes(i18n.global.t("recover.notAllowed"))
  ) {
    errors.value.push(i18n.global.t("recover.notAllowed"));
  }
}

onUnmounted(() => {
  resetComposable();
});

onBeforeRouteLeave(() => {
  if (v.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

const confirmSaveWorkflow = () => {
  confirm.require({
    group: "save-workflow-warning",
    message: i18n.global.t("confirmations.saveWorkflow.message"),
    header: i18n.global.t("confirmations.saveWorkflow.header"),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("save"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: async () => save(),
    reject: () => (submitted.value = false),
  });
};
const confirmDeleteWorkflow = () => {
  confirm.require({
    group: "delete-workflow-warning",
    message: i18n.global.t("confirmations.deleteWorkflow.message"),
    header: i18n.global.t("confirmations.deleteWorkflow.header", {
      workflowName: workflow.value?.name,
    }),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("workflows.delete"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: async () => {
      try {
        await deleteWorkflow(
          currentOrganization.value.key,
          currentEnvironment.value.key as string,
          workflow.value?.key!
        );
        resetComposable();
        await router.push({ name: "WorkflowList" });
      } catch (err) {
        errors.value.push(i18n.global.t("workflows.deleteError"));
      }
    },
  });
};

async function setRecoverConfigurations() {
  try {
    recoverConfigurations.value = await listRecoverConfigurations(
      store.currentOrganization.key,
      store.currentEnvironment.key!
    );

    failureReasons.value = await listFailureReasons(
      store.currentOrganization.key,
      store.currentEnvironment.key!
    );
  } catch (err) {
    errors.value.push(
      i18n.global.t("recover.httpError", {
        support: "support@spreedly.com",
      })
    );
  }
}

const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("organization.create_workflow");
});

const state = reactive({
  name: "",
  sandbox: deepCopy(isSandbox.value),
});
const rules = {
  name: action.value === "create" ? { required } : {},
  sandbox: {},
};

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

watch(
  () => v.value.$anyDirty,
  () => {
    if (v.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

// communicate from deeply nested WorkflowVerticalStepper to parent component
watch(
  () => bus.value.get("removeSplit"),
  (args) => {
    displaySuccessToast(args[0], "workflows.removeSplitSuccessful");
  }
);

watch(
  () => bus.value.get("removeRecover"),
  (args) => {
    displaySuccessToast(args[0], "workflows.removeRecoverSuccessful");
    if (
      !checkForRecoverConfigurations() &&
      errors.value.includes(i18n.global.t("recover.notAllowed"))
    ) {
      errors.value.splice(
        errors.value.indexOf(i18n.global.t("recover.notAllowed")),
        1
      );
    }
  }
);

function displaySuccessToast(steps: WorkflowStepType[], summary: string) {
  draftWorkflow.value = {
    name: state.name,
    sandbox: state.sandbox,
    environment_key: currentEnvironment.value.key,
    steps: steps,
  };
  toast.removeGroup("success-toast");
  toast.add({
    severity: "success",
    group: "success-toast",
    summary: i18n.global.t(summary),
    life: 5000,
  });
}

function checkForRecoverConfigurations() {
  return (
    JSON.stringify(steps.value).match(/"recover":/g) && !recoverAllowed.value
  );
}

async function checkForInvalidForm(isFormInvalid: boolean) {
  if (!v.value.$anyDirty || formState.value === "saving") {
    return;
  }

  errors.value = [];

  if (!hasPermission.value) {
    errors.value.push(i18n.global.t("permission_denied_edit"));
    return;
  }

  submitted.value = true;

  if (isFormInvalid) {
    errors.value.push(i18n.global.t("workflows.validationError"));
    return;
  }

  checkForErrors();
  if (errors.value.length) return;

  if (action.value === "update") {
    confirmSaveWorkflow();
  } else {
    await save();
  }
}
async function save() {
  try {
    formState.value = "saving";
    const workflow = await formatFunctionCall().call(null);

    if (action.value === "create") {
      if (!currentEnvironment.value.default_workflow_key) {
        let env = deepCopy(store.currentEnvironment);
        env.default_workflow_key = workflow.key;
        await updateEnvironment(currentOrganization.value.key, env);
        await store.fillEnvironments(true);
      }

      v.value.$reset();
      submitted.value = false;
      formState.value = "saveChanges";

      await router.push({
        name: "WorkflowSettings",
        params: {
          id: workflow.key,
        },
        state: { successMessage: "true" },
      });
    } else {
      emit("refresh");
      formState.value = "saved";
    }
  } catch (err) {
    errors.value.push(i18n.global.t("errorMessage.generic"));
    formState.value = "saveChanges";
  } finally {
    v.value.$reset();
    submitted.value = false;
  }
}
function formatFunctionCall() {
  if (action.value === "create") {
    return createWorkflow.bind(
      null,
      currentOrganization.value.key,
      currentEnvironment.value.key!,
      {
        workflow: {
          name: state.name,
          sandbox: state.sandbox,
          environment_key: currentEnvironment.value.key,
          steps: steps.value,
        },
      }
    );
  }

  return updateWorkflow.bind(
    null,
    currentOrganization.value.key,
    currentEnvironment.value.key!,
    workflow.value?.key!,
    {
      workflow: {
        name: workflow.value?.name!,
        sandbox: state.sandbox,
        steps: steps.value,
      },
    }
  );
}

function disableForm() {
  return !!(
    !v.value.$anyDirty ||
    formState.value === "saving" ||
    !store.hasComposerEnabledGatewayConnections() ||
    !hasPermission ||
    (errors.value.length &&
      errors.value.includes(i18n.global.t("recover.notAllowed")))
  );
}

async function setupGateways() {
  try {
    await setGateways(
      currentOrganization.value.key,
      currentEnvironment.value.key as string,
      isSandbox.value
    );
  } catch (err) {
    errors.value.push(
      i18n.global.t("gateway_.httpError", {
        support: "support@spreedly.com",
      })
    );
  }
}

async function toggleSandbox() {
  draftWorkflow.value = {
    name: state.name,
    sandbox: deepCopy(isSandbox.value),
    environment_key: currentEnvironment.value.key,
    steps: deepCopy(steps.value),
  };

  isSandbox.value = state.sandbox;
  if (isSandbox.value) {
    errors.value = [];
  }

  if (!failureReasons.value.length) {
    await setRecoverConfigurations();
  }

  checkForErrors();
  await setupGateways();
  toast.add({
    severity: "success",
    group: "success-toast",
    summary: i18n.global.t(
      isSandbox.value
        ? "workflows.sandboxEnabledSuccessfully"
        : "workflows.sandboxDisabledSuccessfully"
    ),
    life: 5000,
  });
}

async function restoreDraftWorkflow() {
  v.value.$touch();
  toast.removeGroup("success-toast");

  await setGateways(
    currentOrganization.value.key,
    currentEnvironment.value.key as string,
    draftWorkflow.value?.sandbox as boolean
  );

  state.name = draftWorkflow.value?.name || "";
  state.sandbox = draftWorkflow.value?.sandbox;
  isSandbox.value = draftWorkflow.value?.sandbox!;
  setSteps(draftWorkflow.value?.steps);
  checkForErrors();
}

async function reset() {
  // capture workflow state before reset in case undo occurs
  draftWorkflow.value = {
    name: state.name,
    sandbox: state.sandbox,
    environment_key: currentEnvironment.value.key,
    steps: deepCopy(steps.value),
  };

  v.value.$reset();
  state.name = "";
  state.sandbox = workflow.value?.sandbox || false;
  isSandbox.value = state.sandbox;
  setSteps();

  toast.add({
    severity: "success",
    group: "success-toast",
    summary: i18n.global.t("workflows.resetSuccessful"),
    life: 5000,
  });

  await setupGateways();
  errors.value = [];
  submitted.value = false;
  checkForErrors();
}

const confirmSandboxToggle = (sandbox: boolean) => {
  confirm.require({
    group: "sandbox-warning",
    message: sandbox
      ? i18n.global.t("confirmations.workflowSandboxOn.message")
      : i18n.global.t("confirmations.workflowSandboxOff.message"),
    header: sandbox
      ? i18n.global.t("confirmations.workflowSandboxOn.header")
      : i18n.global.t("confirmations.workflowSandboxOff.header"),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: sandbox
      ? i18n.global.t("confirmations.workflowSandboxOn.enableSandbox")
      : i18n.global.t("confirmations.workflowSandboxOff.disableSandbox"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: () => toggleSandbox(),
    reject: () => (state.sandbox = !state.sandbox),
    onHide: () => (state.sandbox = !state.sandbox),
  });
};
</script>
